import { toCamelCase } from './to-camel-case';

export const toDBString = (entry: string) => {
    const entryArr = entry && entry.length ? entry.split(' ') : [];

    return entryArr.join('_').toLowerCase().trim();
} 

export const parseDBString = (entry) => {
    const entryArr = entry && entry.length ? entry.split('_') : [];

    return toCamelCase(entryArr.join(' '));
}