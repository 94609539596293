import React from 'react'
/** @jsx jsx */
import { jsx, css, keyframes } from '@emotion/core'
import { Grid, List, ListItem, ListItemAvatar, Avatar, Button, ListItemText, Divider, Badge, ButtonBase, CardContent, Card, Typography, CardActionArea, CardMedia, CardActions, ListSubheader, IconButton, Drawer as Side, Drawer } from '@material-ui/core';
import { Product } from '../data/product-model';
import { headerHeight, mobiHeaderHeight, theme } from '../common/site-variables';
import { useSpring, animated, useSprings } from 'react-spring';
import { flexRowCenterAll } from '../common/format-styles';
import { Category } from '../data/category-model';
import { parseDBString, toDBString } from '../helper/category-parser';
import { navigate } from 'gatsby';
import ProductCard from './productCard';
import styled  from '@emotion/styled';
import ArrowRightIcon from '@material-ui/icons/KeyboardArrowRight';
import ArrowLeftIcon from '@material-ui/icons/KeyboardArrowLeft';
import Pagination from '@material-ui/lab/Pagination';

import {
    breakpoints,
    spacing,
  } from '../utils/styles';
import { getCategories, getProducts } from '../services/product-service';
import AuthContext from '../data/auth-context';


interface PropTypes {
    isMobile: boolean;
}

const ProductListingContainer = styled(`div`)`
  display: flex;
  flex-direction: column;
  justify-content: center;
  padding: ${spacing.lg}px;

  @media (min-width: ${breakpoints.desktop}px) {
    flex-direction: row;
    flex-wrap: wrap;
    padding: ${spacing['2xl']}px;
  }
`;

const ProductComponent = (props: PropTypes) => {
    const { isMobile } = props;
    const [products, setProducts] = React.useState<Product[]>([]);
    const [prodIconSprings, setProdIconSprings] = useSprings(products.length , i => ({right: '-64px'}));
    const [selectedCat, setSelectedCat] = React.useState<Category>();
    const [open, setOpen] = React.useState(!isMobile);
    const [page, setPage] = React.useState(1);
    const [pageCount, setPageCount] = React.useState(0);
    const [categories, setCategories] = React.useState<Category[]>([]);
    const [activeCat, setActiveCat] = React.useState<string>();
    const authState = React.useContext(AuthContext);

    const PRODUCT_LIMIT  = 12;

    React.useEffect(() => {
        
        getProducts(PRODUCT_LIMIT, page, activeCat && activeCat).then(
            res => {
                setProducts(res.data as Product[]);

                const totalCount = activeCat ? res.meta?.filter_count : res.meta?.total_count

                setPageCount(Math.ceil(totalCount / PRODUCT_LIMIT));
            }, errors => console.log(errors)
        )
        
        categories.length === 0 && getCategories().then(
            res => {
                setCategories(res.data as Category[]);
            }, errors => {
                console.log(errors);
            }
        );
    }, [page, activeCat]);

    const activeCss = css(`
        color: ${theme.cs_blue};
        border-right: solid medium ${theme.cs_blue};
    `);

    const [activeCard, setActiveCard] = React.useState(-1);    

    const handleCardMouseEnter = (index: number) => () => {
        setActiveCard(index);
        //@ts-ignore
        setProdIconSprings((i: number) => {
            if (index !== i) return
            
            return {right: '0px'}
        });
    }

    const handleCardMouseLeave = (index: number) => () => {
        setActiveCard(-1);
        //@ts-ignore
        setProdIconSprings((i: number) => {
            if (index !== i) return
            
            return {right: '-64px'}
        });
    }

    const contentShift = keyframes`
        from {widtht: 0}, to {width: 50px}
    `;

    const Root = styled(`section`)`
        display: flex;
        width: 100%;
    `;

    const LoginContainer = styled(ButtonBase)`
        width: 100%;
        background: red;
        color: ${theme.cs_lighter_blue};
        background-color: ${theme.cs_darker_blue};
        border-right: solid medium ${theme.cs_darker_blue};

        h4 {
            margin: 5%;
            font-size: 1.2rem;
        }
    `;

    const drawerWidth = isMobile ? '70%' : '16%';

    const Toggle  = styled(ButtonBase)`
        position: fixed;
        top: ${isMobile ? mobiHeaderHeight : headerHeight};
        left: ${open ? drawerWidth : 0};
        width: ${isMobile ? 12 : 4 }vw;
        height: ${isMobile ? 12 : 4 }vw;
        background-color: ${theme.cs_darker_blue};
        color: white;
    `;

    const Side = styled(Drawer)`
        width: ${drawerWidth};
        flex-shrink: 0;

        .MuiDrawer-paper {
            top: ${isMobile ? mobiHeaderHeight : headerHeight};
            width: ${drawerWidth};
        }
    `;

    const Main = styled(`div`)`
        position: relative;
        width: 84%;
        flex-grow: 1;
        margin-left: ${open ? 0 :`-${drawerWidth}`};
        padding: ${isMobile ? '10% 0' : '5% 0'};
        animation: ${contentShift};
    `;

    const handleDrawerOpen = () => {
        setOpen(!open);
    };

    const handlePageChange = (event: React.ChangeEvent<unknown>, page: number) => {
        setPage(page);
    }

    const handleCatChange  = (category?: Category) => () => {        
        setPage(1);
        setActiveCat(category ? category.id : null);
    }

    return (
        <Root>

            {/* <Grid item lg={2} className="side"> */}
            <Side 
                variant="persistent"
                anchor="left"
                open={open}
            >
                <LoginContainer onClick={() => navigate('/signin')}>
                    <h4>Hello, {authState.dbUser ? `${authState.dbUser.first_name}` : 'Sign in'}</h4>
                </LoginContainer>

                <List 
                    className="categoryList"
                    subheader={
                        <ListSubheader css={css(`font-weight: bold`)}>Shop By Categories</ListSubheader>
                    }
                >  
                    <ListItem 
                        button 
                        onClick={handleCatChange()}
                        className="listItem"
                        css={!selectedCat  && activeCss}
                    >
                        All
                    </ListItem>
                    {categories.map(category => (
                        <ListItem 
                            button 
                            onClick={handleCatChange(category)}
                            key={category.id}
                            className="listItem"
                            css={(selectedCat && selectedCat.title === category.title) && activeCss}
                        >
                            {parseDBString(category.title)}
                        </ListItem>
                    ))}
                </List>
            </Side>
            {/* </Grid> */}

            <Main className="main">
                <Toggle onClick={handleDrawerOpen}>
                    {open ?
                        <ArrowLeftIcon fontSize="large" />
                        :
                        <ArrowRightIcon fontSize="large" />
                    }
                </Toggle>
                <ProductListingContainer>
                
                    {products.length 
                        ?
                        prodIconSprings.map((props, index) => (
                            <ProductCard 
                                product={products[index]} 
                                onMouseEnter={handleCardMouseEnter(index)} 
                                onMouseLeave={handleCardMouseLeave(index)}
                                detProps={props} 
                                active={activeCard === index}
                                descCharCount={120}
                                key={products[index].id}
                            />
                        ))
                        :
                        <div>
                            <p>No products in this category</p>
                        </div>
                    }
                </ProductListingContainer>

                <div className="pagination" css={flexRowCenterAll}>
                    <Pagination count={pageCount} onChange={handlePageChange} page={page} />
                </div>
            </Main>

        </Root>
    )
}

export default ProductComponent
