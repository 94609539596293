import React, { SyntheticEvent, useState } from 'react'
import { Snackbar, IconButton } from '@material-ui/core'
import CloseIcon from '@material-ui/icons/Close';

interface PropTypes {
    open: boolean;
    msg: string;
    handleState: (open: boolean) => void;
}

const Notification = (props: PropTypes) => {
    const { open, msg, handleState } = props;

    const handleClose = (event: React.SyntheticEvent | React.MouseEvent, reason?: string) => {
        if (reason === 'clickaway') {
          return;
        }
    
        handleState(false);
    };

    const handleClick = (event: React.SyntheticEvent | React.MouseEvent, reason?: string) => {
    
        handleState(false);
    };

    return (
        <Snackbar
            anchorOrigin={{
            vertical: 'bottom',
            horizontal: 'left',
            }}
            open={open}
            autoHideDuration={5000}
            onClose={handleClose}
            message={msg}
            action={
                <React.Fragment>
                    <IconButton size="small" aria-label="close" color="inherit" onClick={handleClick}>
                        <CloseIcon fontSize="small" />
                    </IconButton>
                </React.Fragment>
            }
        />
    )
}

export default Notification
