import React from 'react'
import Layout from '../components/layout'
import SEO from '../components/seo'
import ResponsiveView from '../responsive/view'
import ProductComponent from '../components/products-component'
import FloatingCart from '../components/floating-cart'
import Notification from '../components/notification'

const Products = () => {

    const [open, setOpen] = React.useState(false);
    const [msg, setMsg] = React.useState('');

    const handleSnackState = (open: boolean) => {
        setOpen(open);
    }


    return (
        <Layout>
            <SEO title="Products" />
            <ResponsiveView render={isMobile => (
                <FloatingCart isMobile={isMobile} />
            )} />
            <ResponsiveView render={isMobile => (
                <ProductComponent isMobile={isMobile} />
            )} />

            <Notification handleState={handleSnackState} msg={msg} open={open} />
        </Layout>
    )
}

export default Products
